<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_addbooking" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
    <template #default="{ hide }">
        <div class="m-1 text-start" style="direction:rtl">
            <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{ lang.add_booking }}</span>
            </div>
            <div @click="hide" id="hideaddbooking" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{ lang.close }}</span>
            </div>
        </div>
        <v-divider></v-divider>
        <v-row style="direction:rtl" class="m-2">
            <v-col cols="12" md="12" sm="12">
                <label>{{lang.task_description}}</label>
                <b-form-textarea class="inborder"
                    v-model="task_description"
                    :label="lang.task_description"
                    value=""
                    required
                    ></b-form-textarea>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.start_date}}</label>
                <b-form-datepicker class="inborder"
                    v-model="start_date"
                    :label="lang.start_date"
                    value=""
                    required
                    ></b-form-datepicker>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.start_time}}</label>
                <b-form-timepicker class="inborder"
                    v-model="start_time"
                    :label="lang.start_date"
                    value=""
                    required
                    ></b-form-timepicker>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.dead_line}}</label>
                <b-form-datepicker class="inborder"
                    v-model="dead_line"
                    :label="lang.dead_line"
                    value=""
                    required
                    ></b-form-datepicker>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.deadline_time}}</label>
                <b-form-timepicker class="inborder"
                    v-model="dead_lin_time"
                    :label="lang.deadline_time"
                    value=""
                    required
                    ></b-form-timepicker>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.task_to}}</label>
                <b-form-select class="selborder"
                    v-model="task_to"
                    :options="userslist"
                    required
                    />
            </v-col>
            <!-- <v-col cols="12" md="4" sm="12">
                <label>{{lang.plate_number}}</label>
                <b-form-select class="selborder"
                    v-model="plate_number"
                    :options="plates"
                    required
                    />
            </v-col> -->
            <v-col cols="12" md="6" sm="12">
                <label>{{lang.status}}</label>
                <b-form-select class="selborder"
                    v-model="status"
                    :options="statusOptions"
                    required
                    />
            </v-col>
        </v-row>
    </template>
                <template #footer="">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addTasks()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            customerid:0,
            task_description: '',
            start_date: '',
            start_time: '',
            dead_line: '',
            dead_lin_time: '',
            task_to: '',
            userslist: [],
            user: {},
            status: '1',
            statusOptions: [],
            plate_number: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        plates: function(){
            const t = [];
            for(let i=0;i<this.$parent.cars.length;i++){
                t.push({text: this.$parent.cars[i].plate_number,value: this.$parent.cars[i].plate_number})
            }
            return t;
        }
    },
    methods: {
        async getUsers(){
            const post = new FormData();
            post.append("type" , "getUsers");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[status]',1);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            if(response && response.data && response.data.results && response.data.results.data && response.data.results.data.results){
                const res = response.data.results.data.results;
                for(let i = 0;i < res.length;i++){
                    this.userslist.push({text: res[i].full_name, value: res[i].id})
                        if(res[i].id == this.user.id){
                            this.task_to = res[i].id;
                        }
                }
            }
        },
        async addTasks(){
            if(this.task_description == ''){
                let message = this.lang.task_description_required;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.start_time == '' || this.dead_lin_time == ''){
                let message = this.lang.start_time_required;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.start_date == '' || this.dead_line == ''){
                let message = this.lang.task_start_end_date_required;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type" , "addSamCalendar");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[doc_type]", "warrantly")
            post.append("data[docid]",this.customerid);
            post.append("data[plate_number]",this.plate_number);
            post.append("data[description]",this.task_description);
            post.append("data[start_date]",this.start_date);
            post.append("data[start_time]",this.start_time);
            post.append("data[deadline]",this.dead_line);
            post.append("data[deadline_time]",this.dead_lin_time);
            post.append("data[userid]",this.task_to);
            post.append("data[status]",this.status);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                let message = 'تمت الاضافة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getCustomerInfo();
                document.getElementById('hideaddbooking').click();
            });
            
            this.message = '';
        },
        getCurrent(){
            const post = new FormData();
            post.append("type" , 'getUsers');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[userid]','current');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.user = res.results.data.results[0];
                    this.getUsers();
                }
            )
        },
        getStatus(status){
            const post = new FormData();
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.statusOptions.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
    },
    created() {
        this.getCurrent();
        this.getStatus('Calendar');
    },
}
</script>